import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from '../../../../../style';

export const AccountContentContainer = styled(BasicSectionContainer)`

  .accountBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: stretch;
    gap: 32px;
    .loading {
      padding: 100px;
      color: ${colors.borderGrey};
      font-size: 30px;
    }
    .cardContent {
      min-width: 260px;
      -webkit-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
      -moz-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
      box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
      border: 1px solid ${colors.accounBorderColor};
      padding: 32px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      align-content: stretch;
      flex: 1;
      color: ${colors.commonBlack};

      .guestsHeader {
         font-size: 24px;
         font-weight: 600;
         line-height: 24px;
         margin-bottom: 32px;
       }

      .guestsTableHeader {
        justify-items: stretch;
        width: 100%;
        display: grid;
        grid-template-columns: 219fr 238fr 258fr 258fr 63fr;
        font-size: 15px;
        font-weight: 600;
        color: ${colors.black};
        padding: 20px 0;
        border-bottom: 1px solid ${colors.black};
        //border-top: 1px solid ${colors.black};
        flex: 1;
        .col {
          text-align: left;
          font-size: 15px;
          font-weight: 600;
          line-height: 21px;
          padding-right: 10px;
        }
      }

      .guestsTableContent {
        justify-items: stretch;
        width: 100%;
        display: grid;
        grid-template-columns: 219fr 238fr 258fr 258fr 63fr;
        font-size: 16px;
        font-weight: 600;
        color: ${colors.black};
        padding: 20px 0;
        border-bottom: 1px solid ${colors.black};
        .col {
          text-align: left;
          font-size: 15px;
          font-weight: 400;
          line-height: 21px;
          padding-right: 10px;
        }
        .name {
          font-weight: 600;
        }
        .edit {
          color: ${colors.hoverBlue};
          text-decoration: underline;
          cursor: pointer;
        }
        }

    }
    .room {
      font-size: 27px;
      font-weight: 600;
      margin-bottom: 20px;
      
    }
    .detailsHeader {
      display: flex;
      flex-direction: row;
      gap: 5px;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 38px;
    }
    .detailsSmall {
      display: flex;
      flex-direction: row;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 21px;
      line-height: 19px;
      align-items: center;
      span {
        font-weight: 400;
        margin-right: 15px;
        margin-left: 15px;
        font-size: 16px;
      }
    }
    .detailsLarge {
      display: flex;
      flex-direction: row;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
      line-height: 20px;
      align-items: center;
      span {
        font-weight: 400;
        margin-left: 15px;
        font-size: 16px;
      }
    }
    .breakDown {
      margin-top: 16px;
    }
    .breakDownTotal {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      gap: 5px;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
      margin-top: 8px;
      .breakDownNumber{
        font-size: 18px;
        font-weight: 700;
      }
      }
      .downloadReceipt {
        display: flex;
        flex-direction: row;
        align-content: flex-end;
        justify-content: flex-end;
      }
    .breakDownLine {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      gap: 5px;
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 15px;
      .breakDownDots {
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-left: 10px;
        margin-right: 5px;
      }
      .dashedLine {
        width: 100%; /* The width of the line will match the width of the container */
        height: 1px; /* Height of the line */
        background: repeating-linear-gradient(
          to right,
          ${colors.borderGrey2} 0,
          ${colors.borderGrey2} 1px,
          /* Length of the dash */ transparent 2px,
          transparent 4px /* Length of the space */
        );
        border: none; /* Remove any default border if present */
      }

      .dashedLineRed {
        width: 100%; /* The width of the line will match the width of the container */
        height: 1px; /* Height of the line */
        background: repeating-linear-gradient(
          to right,
          ${colors.red} 0,
          ${colors.red} 1px,
          /* Length of the dash */ transparent 2px,
          transparent 4px /* Length of the space */
        );
        border: none; /* Remove any default border if present */
      }



      .breakDownNumber {
        min-width: 50px;
        text-align: right;
      }
    }
    .breakDownLineRed {
        .breakDownTitle {
          color: ${colors.red};
          font-weight: 600;
        }
        .breakDownNumber {
          color: ${colors.red};
          font-weight: 600;
        }
      }
    .accountLink {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .link {
        font-size: 16px;
        font-weight: 600;
        color: ${colors.hoverBlue};
        text-decoration: underline;
      }
    }

  }

  /* .card {

    .cardTitle {
      font-size: 18px;
      font-weight: 600;
      color: ${colors.black};
      margin-bottom: 16px;

      .highLight {
        color: ${colors.hoverBlue};
      }
    }

    .cardContent {

      .breakDownRow {
        .breakDownLabel {
          font-size: 14px;
          font-weight: 600;
          color: ${colors.black};
          margin-bottom: 8px;
        }

        .breakDownValue {
          font-size: 16px;
          color: ${colors.black};
        }
      }

      .buttonContainer {
        position: relative;

        svg {
          position: absolute;
          top: -85px;
          left: 150px;
        }
      }
    }
  } */
`;
