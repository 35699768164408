import { green, lightBlue, orange, yellow } from "@material-ui/core/colors";
import UnsubscribeScreen from "screens/UnsubscribeScreen";

export const colors = {
  black: '#000000',
  commonBlack: '#1D1D1D',
  almostBlack:'#1D1D1F',
  red: '#FF0000',
  orange: '#FF5501',
  yellow: '#FFC500',
  green: '#2EAA69',
  grey: '#888888',
  lightBlue: '#CCE8FA',
  lightBlueTransp: '#f4fafe',
  mask: '#190031',
  labelGrey: '#818181',
  userSelectionBlack: '#393939',
  homeTitleBlack: '#383838',
  white: '#FFFFFF',
  checkGreen: '#388951',
  radioGreen: '#4E964B',
  setPasswordBackground: '#044D4A',
  forgotPasswordBackground: '#3C6684',
  setPhoneNumberBackground: '#3C8E63',
  unsubscribeBackground: '#227989',
  tooltip: '#108AA7',
  closeRed: '#D64B4B',
  menuGrey: '#454545',
  searchBarBackground: '#EFEFEF',
  errorRed: '#BF5252',
  highlightBlue: '#62A8EF',
  iconBlue: '#056BD1',
  hoverBlue: '#038DE3',
  buttonHoverDarkBlue: '#004080',
  lightHoverBlue: '#A7DAF5',
  textInputLabel: '#696363',
  badge: '#E01E1E',
  checkBoxCheck: '#0F6BAF',
  close: '#0365A2',
  callButton: '#12AF6C',
  chatButton: '#0C2DBA',
  directionButton: '#6828E0',
  homeTipsBorder: '#B7EAE8',
  homeDescription: '#404040',
  borderGrey: '#9F9F9F',
  borderGrey2: '#707070',
  borderGrey3: '#D0D0D0',
  virtualTourBackground: '#517A84',
  ratingStar: '#E2AD20',
  reviewStar: '#F0860B',
  reviewsBackground: '#E3E3E3',
  roomCardBackground: '#F0F0F0',
  roomCardDotBackground: '#2F2F2F',
  roomCardTextColor1: '#525252',
  roomCardTextColor2: '#5D5B5B',
  roomCardTextColor3: '#313131',
  roomCardTextColor4: '#CE1A1A',
  roomCardHighlightBackground: '#1CB400',
  homeTitle: '#404040',
  generalInfoTextColor1: '#EF6806',
  generalInfoTextColor2: '#043E9B',
  generalInfoTextColor3: '#9F9F9F',
  generalInfoTextColor4: '#2F2F2F',
  generalInfoIconBorder: '#57DD26',
  optionBackground: '#2EAA69',
  phoneNumberHighlight: '#278312',
  priceInfoDescription: '#333333',
  roomDetailsTextColor1: '#575757',
  readMoreButtonTextColor: '#1388D1',
  checkOutBackgroundColor: '#DBF0F0',
  checkOutTableTextColor: '#D5D5D5',
  checkOutPriceTextColor: '#7C7C7C',
  checkOutPriceInfoBorder: '#B7E2CB',
  summaryAlertColor: '#259A88',
  paymentAlertColor: '#0D0D0D',
  tripTextColor: '#898989',
  tripTextColor2: '#B93F3F',
  tripTableBorder: '#343434',
  modalBackground: '#1D1D1F',
  modalTextColor: '#B3B3B3',
  checkOutCompleteScreenBackground: '#161929',
  checkOutCompleteScreenHighlight: '#E0E013',
  checkOutCompleteScreenInputHighlight: '#E83636',
  signInBackgroundColor: '#241E5E',
  signInBorderColor: '#CECECE',
  resetPasswordBackgroundColor: '#40497C',
  saveButtonBackground: '#EAEAEA',
  contactFooterTitle: '#E2E2E2',
  offerBackgroundColor: '#CFE6EF',
  healthAlertBackgroundColor: '#B9EDCB',
  healthAlertDescriptionColor: '#070707',
  topAlert: '#FF7D6C',
  questionBorder: '#87D99A',
  tenantInfoBackground: '#F1F5F8',
  reviewBorder: '#5C0D96',
  reviewContent: '#FAD1FF',
  cancellationPolicyContent: '#E1F8EE',
  cancellationPolicyBorder: '#19C180',
  contactBorder: '#ADA8C0',
  contactInputBackground: '#1B0837',
  backgroundGreen: '#4DA1B1',
  unsubscribeText: '#7C50B8',
  placeholderGrey: '#D1D1D1',
  backgroundGrey: '#B1B1B1',
  footerDetailsBackground: '#232323',
  resetBackground: '#DBEBD8',
  changeRoomTypeBackground: '#D5F8F2',
  questionLinkText:'#339A14',
  questionLinkBorder:'#76CB5E',
  helpBoxBackground:'#E1F3EA',
  helpBoxBorder:'#B9B7B7',
  helpBoxBorderHover:'#3498DB',
  accounMenuBorderShadowColor: 'rgba(0,0,0,0.16)',
  accounMenuBorderColor: '#DFDFDF',
  accounBorderColor: '#d4d4d4',
  noBookingText: '#808080',
  questionLinkBackground:'#DEF2E0',
  secLabel :'#6E6E73',
  profileBorder :'#C9C9C9',
  inputDisabled: '#EBEBEB'
};
