import { atom } from 'recoil';
import { SelectedRooms, Coupon, PaymentInfo, UserInfo } from 'types/commonTypes';
import { getPersistSelectedRooms, getPersistRatePlanId, getPersistCoupon, getPersistBoolean } from 'utils/util';

export const selectedRooms = atom<SelectedRooms>({
  key: 'selectedRooms',
  default: getPersistSelectedRooms(),
});

export const countDownTimer = atom({
  key: 'countDownTimer',
  default: 900,
});

export const ratePlanChoice = atom({
  key: 'ratePlanChoice',
  default: getPersistRatePlanId(),
});

export const termsOfServiceState = atom({
  key: 'termsOfServiceState',
  default: getPersistBoolean('termsOfServiceChecked'),
});

export const termsOfServiceErrorState = atom({
  key: 'termsOfServiceErrorState',
  default: false,
});


export const couponState = atom<Coupon>({
  key: 'couponState',
  default: getPersistCoupon(),
});

export const couponSuccessState = atom({
  key: 'couponSuccessState',
  default: !!getPersistCoupon().couponId,
});

export const paymentInfoState = atom<PaymentInfo>({
  key: 'paymentInfoState',
  default: {
    cardHolderName: '',
    creditCardNumber: '',
    cardExpirationDate: '',
    cardCVV: '',
    billingAddress: '',
  },
});

export const userInfoState = atom<UserInfo>({
  key: 'userInfoState',
  default: {
    userId: localStorage.getItem('userId') || '',
    fullNameInput: '',
    emailInput: '',
    mailAddressInput: '',
    phoneNumberInput: '',
    sameAsBill: false
  },
});