import React, { FC } from 'react';
import { AccountContentContainer } from './style';
import Card from 'components/Card';

const ModifyDatesContent: FC = () => {

  return (
    <AccountContentContainer>
      <Card
        padding='32px'
      >
        <div className="cardContent">
          Modify Dates
        </div>
      </Card>
    </AccountContentContainer>
  );
};

export default ModifyDatesContent;
