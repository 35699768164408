import React, { FC, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil'
import { roomQuantityState } from 'states/SearchBarStates/atoms';
import { selectedRooms } from 'states/CheckOutStates/atoms';
import StyledBadge from '../Badge';
import StyledButton from '../Button'
import { HeaderContainer, HotelTitle } from './style';
import { HeaderIcon } from 'assets/icons';
import { PersonIcon, NewLogoIcon } from 'assets/icons';
import HeaderMenu from 'components/HeaderMenu';
import { Icon } from 'components/Icon';
import { useAuth } from 'customHooks/useAuth';

const Header: FC = () => {

  const { validToken } = useAuth();
  const [isActiveIcon, setActiveIcon] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(!!validToken)
  const history = useHistory();
  const roomQuantity = useRecoilValue(roomQuantityState)
  const persistSelectedRooms = useRecoilValue(selectedRooms)

  // const componentRef = useRef<HTMLDivElement | null>(null);
  // const handleClickOutside = (event: MouseEvent) => {
  //   if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
  //     setActiveIcon(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  useEffect(() => {
    setIsSignedIn(!!validToken)
  }, [validToken, setIsSignedIn])

  return (
    <HeaderContainer>
      <div className="innerContainer">
        <div
          className="navTitle"
          onClick={() => {
            history.push('/');
          }}>
          <Icon
            width={42}
            height={31}
          >
            <NewLogoIcon />
          </Icon>
          <HotelTitle>
            <span>ROOK</span> HOTEL
          </HotelTitle>
        </div>
        <div className="HeaderContent">
          <div
            className="HeaderLink"
            onClick={() => {
              history.push('/about');
            }}
          >
            About Rook
          </div>
          <div
            className="HeaderLink"
            onClick={() => {
              history.push('/discover');
            }}
          >
            Discover
          </div>
          <div
            className="HeaderLink"
            onClick={() => {
              history.push('/contact');
            }}
          >
            Contact us
          </div>
        </div>
        {isSignedIn? null : (
          <StyledButton
            className="signInButton"
            width="63px"
            fontSize={14}
            height="38px"
            onClick={() => {
              history.push('/signIn');
            }}
          >
            Sign in
          </StyledButton>
        )}
        {/* <StyledBadge
          className="navBar"
          invisible = {Object.keys(persistSelectedRooms).length === 0}
          badgeContent={roomQuantity}
          onClick={() => {
            setActiveIcon(!isActiveIcon)
          }}
        >
          <Icon isActiveIcon={false}>
            <HeaderIcon />
          </Icon>
          <Icon
            omitted = {!isSignedIn}
            customerStyle
            activeIconStyle            
            isActiveIcon={isActiveIcon}
            style ={{marginRight: '-10px'}}
          >
            <PersonIcon />
          </Icon>
          {isActiveIcon? (
            <div className='menuContainer' ref={componentRef}>
              <HeaderMenu />
            </div>
          ) : null}
        </StyledBadge> */}
      </div>
    </HeaderContainer>
  );
};
export default Header;
