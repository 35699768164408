import React, { FC, useEffect } from 'react';
import { PersonalDetails } from './style';
import FormInputField from 'components/FormInputField';
import { colors } from 'constants/colors';
import StyledButton from 'components/Button';
import Hint from 'components/Hint';
import { useState  } from 'react';



const PersonalDetailsContent: FC = () => {

  const [fullNameValue, setFullNameValue] = useState('John Doe');
  const [addressValue, setAddressValue] = useState('99 Address, City, PV, Canada');
  const [dateOfBirth, setDateOfBirth] = useState('11/11/1960');
  const [isCleared, setIsCleared] = useState(false); // Indicator to track if the field has been cleared


  const [showHint, setShowHint] = useState<boolean>(false);



  const handleMouseOver = () => {
    setShowHint(true);  
  };

  const handleMouseOut = () => {
    setShowHint(false); // Прячем хинт, когда мышь уходит с иконки
  };



  const formatDate = (value: string) => {
    // Remove all non-digit characters
    value = value.replace(/[^\d]/g, '');

    // Add slashes after the 2nd and 5th characters
    if (value.length >= 2) value = value.slice(0, 2) + '/' + value.slice(2);
    if (value.length >= 5) value = value.slice(0, 5) + '/' + value.slice(5);

    // Limit the length to 10 characters (dd/mm/yyyy)
    return value.slice(0, 10);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    setDateOfBirth(formatDate(value)); // Format the date with slashes added
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isCleared) {
      // If the field has not been cleared, clear it and set the first entered character
      setIsCleared(true); // Mark that the field has been cleared
      setDateOfBirth(formatDate(e.key)); // Set the first entered character with slashes added
      e.preventDefault(); // Prevent default input to avoid adding the character twice
    }
  };

  const handleFocus = () => {
    setIsCleared(false); // Reset the clearing state when focused
  };

  return (
    <PersonalDetails>
      <div className="accountBody">
        <div className="cardContent">
          <div className="topText">
            It is important to review and verify that all details are correct
            and current. Keeping your information accurate ensures you receive
            important updates and notifications without any issues. Please make
            any necessary updates to reflect your latest contact information.
          </div>
          <div className="inpuntCont fullName">
            <FormInputField
              className="secInput"
              // highLightLabel='CURRENT '
              label="FULL NAME"
              isRequired
              type="text"
              borderColor={colors.signInBorderColor}
              // error={oldPasswordError}
              // errorMessage={errorMessageOldPass}
              // value={oldPassword}
              inputHeight="48px"
              marginBottom="20px"
              value={fullNameValue}
              maxLength={300}
              onChange={(e) => {
                setFullNameValue(e.target.value);
              }}
            />
          </div>
          <div className="inpuntCont address">
            <FormInputField
              className="secInput"
              // highLightLabel='CURRENT '
              label="ADDRESS"
              isRequired
              type="text"
              borderColor={colors.signInBorderColor}
              // error={oldPasswordError}
              // errorMessage={errorMessageOldPass}
              value={addressValue}
              inputHeight="48px"
              marginBottom="20px"
              onChange={(e) => {
                setAddressValue(e.target.value);
              }}
              // onChange={(e) => {
              //   setOldPassword(e.target.value)
              //   setNewPasswordSucsess (false)
              //   if(oldPasswordError) {
              //     setOldPasswordError(false)
              //     setNewPasswordSucsess (false)
              //     setSowResetPasswordHint(false)
              //   }
              // }}
            />
          </div>
          <div className="inpuntCont dateOfBirth">
            <FormInputField
              className="secInput"
              // highLightLabel='CURRENT '
              label="DATE OF BIRTH (dd/mm/yyyy)"
              isRequired
              type="text"
              borderColor={colors.signInBorderColor}
              // error={oldPasswordError}
              // errorMessage={errorMessageOldPass}
              value={dateOfBirth}
              onChange={handleDateChange}
              onClick={handleFocus}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown} // Clear the field on the first input
              onMouseOver={handleMouseOver} 
              onMouseOut={handleMouseOut}
              // maxLength={11}

              inputHeight="48px"
              marginBottom="20px"
              infoIcon
            />
            {/* <InfoCircle className="infoIcon"></InfoCircle> */}
            {showHint? (
            <Hint
              title="Why do you need this?"
              message="To stay at our hotel, the primary guest must be 19+ years old."

              width="446"
              arrowPosition="22"
              positionBottom="88"
              positionLeft="200"

              widthTablet="446"
              arrowPositionTablet="223"
              positionBottomTablet="88"
              positionLeftTablet="0"

              widthMini="446"
              arrowPositionMini="223"
              positionBottomMini="88"
              positionLeftMini="-10"

              widthMobile="325"
              arrowPositionMobile="223"
              positionBottomMobile="88"
              positionLeftMobile="0"

            />
            ):null}
          </div>
          <div className="saveButton">
            <StyledButton
              width="81px"
              height="48px"
              //backgroundColor = {colors.signInBorderColor}
              //fontColor = {colors.white}
              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              // disabled={oldPassword.length < 8 || newPassword.length < 8}
              // onClick={handleResetPassword}
            >
              Save
            </StyledButton>
          </div>
        </div>
      </div>
    </PersonalDetails>
  );
};

export default PersonalDetailsContent;
