import { gql } from '@apollo/client';

//Queries
export const GET_USER_INFO_BY_USER_ID = gql`
  query getUserInfoByUserId($userId: String!) {
    userById(id: $userId) {
      userId: id
      fullName
      email
      phoneNumber
      address
    }
  }
`;

export const GET_AVAILABLE_ROOM_TYPES_BY_DATE_RANGE = gql`
  query getAvailableRoomTypesByDateRange($input: RoomTypeByDateRangeInput!) {
    availableRoomTypesByDateRange(data: $input) {
      roomTypeId: id
      name
      description
      capacity
      bed
      hasWindows
      pricePerNight
      availableRooms
      # features
    }
  }
`;

export const GET_AVAILABLE_ROOMS_BY_ROOM_TYPE_ID_AND_DATE_RANGE = gql`
  query getAvailableRoomsByRoomTypeIdAndDateRange($input: RoomAvailabilityInput!) {
    roomsAvailability(data: $input) {
      roomId: id
      roomNumber
      roomType {
        name
        pricePerNight
      }
    }
  }
`;

export const GET_TENANT_INFO_BY_USER_ID = gql`
  query getTenantInfoByUserId($userId: String!) {
    tenantByUserID(userId: $userId) {
      tenantId: id
      groups {
        group {
          groupId: id
          # status
          isSingleton
          isDeleted
          numberOfRooms
          numberOfGuests
          tenants {
            tenant {
              user {
                userId: id
                fullName
                phoneNumber
              }
            }
          }
          addOns {
            groupSubscribedAddOnId: id
            bookingAddOn {
              addOnId: id
              label
            }
          }
          bookings {
            bookingId: id
            reservationCode
            checkOutDate
            checkInDate
            fullName
            phoneNumber
            checkInDate
            checkOutDate
            tenantGroup {
              isPrimaryTenant
              tenant {
                user {
                  userId: id
                  fullName
                  phoneNumber
                }
              }
            }
            roomBookings {
              pricePerNight
              numberOfNights
              totalPrice
              room {
                roomId: id
                roomNumber
                roomType {
                  roomTypeId: id
                  name
                  bed
                }
              }
            }
          }
          bills {
            billId: id
            subTotal
            MAT
            HST
            total
            updatedAt
          }
          reviews {
            reviewId: id
            rating
            comment
          }
        }
      }
      paymentCards {
        paymentCardId: id
        holderName
        billingAddress
        transaction {
          creditCardNumber: field1008
          cardExpirationDate: field0004
        }
        isPrimary
      }
    }
  }
`;

export const GET_ALL_BOOKING_ADD_ONS = gql`
  query getAllBookingAddOns {
    validBookingAddons {
      addOnId: id
      title
      label
      description
      pricePerNight
      isOneTimePriced
    }
  }
`;

export const APPLY_COUPON = gql`
  query applyCoupon($couponCode: String!) {
    couponByCode(couponCode: $couponCode) {
      couponId: id
      discount
      couponCode
      rateType
      isActive
      validFrom
      validTo
    }
  }
`;

// Mutation
export const SIGN_IN = gql`
  mutation signIn($input: LoginInput!) {
    authenticate(data: $input) {
      accessToken
    }
  }
`;

export const SIGN_UP = gql`
  mutation signUp($input: SignUpInput!) {
    signupTenant(data: $input) {
      id
    }
  }
`;

export const VERIFY_ACCOUNT = gql`
  mutation verifyAccount($input: VerifyAccountInput!) {
    verifyAccount(data: $input) {
      accessToken
    }
  }
`;

export const SETUP_USER_PASSWORD = gql`
  mutation setupUser($input: UserSetupInput!) {
    setupUserPassword(data: $input) {
      id
    }
  }
`;

export const SEND_PHONE_NUMBER_VERIFICATION_CODE = gql`
  mutation sendPhoneNumberVerificationCode($input: SendPhoneNumberVerificationSMSInput!) {
    sendPhoneNumberVerificationSMS(data: $input) 
  }
`;

export const VERIFY_PHONE_NUMBER = gql`
  mutation verifyPhoneNumber($input: VerifyPhoneNumberInput!) {
    verifyPhoneNumber(data: $input) 
  }
`;

export const CREATE_GROUP_BOOKING = gql`
  mutation createGroupBooking($input: GroupTenantBookingInput!) {
    createGroupBooking(data: $input) {
      resetToken
      bookings {
        reservationCode
        tenantGroup {
          tenant {
            user {
              userId: id
              email
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_BOOKING_BY_BOOKING_ID = gql`
  mutation updateBookingByBookingId($bookingId: String!, $input: UpdateBookingInput!) {
    updateBooking(id: $bookingId, data: $input) {
      id
    }
  }
`;

export const UPDATE_ROOM_TYPE_BY_BOOKING_ID = gql`
  mutation updateRoomTypeByBookingId($bookingId: String!, $input: UpdateBookingRoomTypeInput!) {
    updateBookingRoomType(bookingId: $bookingId, data: $input) {
      id
    }
  }
`;

export const UPDATE_GROUP_BY_GROUP_ID = gql`
  mutation updateGroupByGroupId($groupId: String!, $input: UpdateGroupInput!) {
    updateGroup(id: $groupId, data: $input) {
      id
    }
  }
`;

export const RESET_EMAIL_BY_TOKEN = gql`
  mutation resetEmailByToken($input: ResetEmailInput!) {
    resetEmail(data: $input) {
      userId: id
    }
  }
`;

export const UPDATE_USER_INFO_BY_USER_ID = gql`
  mutation updateUserInfoByUserId($userId: String! ,$input: UpdateUserInput!) {
    updateUser(id: $userId, data: $input) {
      userId: id
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(data: $input) {
      userId: id
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(data: $input) {
      userId: id
    }
  }
`;

export const CREATE_PAYMENT_CARD = gql`
  mutation createPaymentCard($input: CreatePaymentCard!) {
    createTenantPaymentCard(data: $input) {
      id
    }
  }
`;

export const DELETE_CREDIT_CARD = gql`
  mutation deleteCreditCard($paymentCardId: String!) {
    removePaymentCard(id: $paymentCardId) {
      id
    }
  }
`;

export const SET_CREDIT_CARD_PRIMARY = gql`
  mutation setCreditCardPrimary($input: SetPrimaryPaymentCard!) {
    setPrimaryPaymentCard(data: $input) {
      id
    }
  }
`;

export const CREATE_REVIEW_BY_GROUP_ID = gql`
  mutation createReviewByGroupId($input: CreateBookingReviewInput!) {
    createBookingReview(data: $input) {
      id
    }
  }
`;

export const UPDATE_REVIEW_BY_GROUP_ID = gql`
  mutation updateReviewByGroupId($groupId: String!, $input: UpdateBookingReviewInput!) {
    updateBookingReview(groupId: $groupId, data: $input) {
      id
    }
  }
`;

//FIXME: api need modification
export const DELETE_GROUP_BY_GROUP_ID = gql`
  mutation deleteGroupByGroupId($groupId: String!) {
    deleteGroup(id: $groupId) {
      isDeleted
    }
  }
`;

//FIXME: api need modification
export const DELETE_BOOKING_BY_BOOKING_ID = gql`
  mutation deleteBookingByBookingId($bookingId: String!) {
    deleteBooking(id: $bookingId) {
      status
    }
  }
`;

//FIXME: api does not exist yet
export const UPDATE_EMAIL_BY_USER_ID = gql`
  mutation updateEmailByUserId($userId: String!, $input: UpdateEmailInput!) {
    updateEmailByUserId(id: $userId, data: $input) {
      id
    }
  }
`;

export const UPDATE_PASSWORD_BY_USER_ID = gql`
  mutation updatePasswordByUserId($userId: String!, $input: ChangePasswordInput!) {
    changePassword(id: $userId, data: $input) {
      id
    }
  }
`;

export const SUBSCRIBE = gql`
  mutation subscribe($input: CreateEmailSubscriptionInput!) {
    createEmailSubscription(data: $input) {
      id
    }
  }
`;

export const UNSUBSCRIBE = gql`
  mutation unsubscribe($unsubscribeId: String!) {
    emailUnsubscription(id: $unsubscribeId) {
      id
    }
  }
`;

export const RESUBSCRIBE = gql`
  mutation resubscribe($unsubscribeId: String!) {
    emailResubscription(id: $unsubscribeId) {
      id
    }
  }
`;

export const CREATE_UNSUBSCRIPTION_REASON = gql`
  mutation createUnsubscriptionReason($unsubscribeId: String!,$input: AddEmailReSubscriptionCancellationInput!) {
    emailUnsubscriptionReason(id: $unsubscribeId, data: $input)
  }
`;
