import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AccountContentContainer } from './style';
import Card from 'components/Card';
import { useLoadTrips } from 'customHooks/useLoadTrips';
import { ADVANCED, ADVANCED_RATE } from 'constants/ratePlanId';
import { HST_RATE, MAT_RATE } from 'constants/constant';
import moment from 'moment';
import { EditIcon, InfoCircle, DownloadRecept } from 'assets/icons';
import { Icon } from 'components/Icon';

const debug = true;

const BookingDetailsContent: FC = () => {

  const history = useHistory()
  const { trips, loading, refetch } = useLoadTrips()
  const { groupId } = useParams<{groupId: string}>()
  const trip = trips[groupId]



  if (loading || !trip) {
    return (
      <AccountContentContainer>
      <div className="accountBody">
        <div className="loading">Loading...</div>
      </div>
      </AccountContentContainer>
    );
  }


  const statusObj = {
    CHECKED_IN: {
      className:'checkedIn',
      text: 'CHECKED IN'
    },
    CHECKED_OUT: {
      className:'checkedOut',
      text: 'CHECKED OUT'
    },
    INCOMPLETE: {
      className:'incomplete',
      text: 'INCOMPLETE'
    },
    CONFIRMED: {
      className:'confirmed',
      text: 'CONFIRMED'
    }
  }


  /* Consts for output: */

  

  /* need to update it once API will return real status*/
  const trip_status = statusObj[trips[groupId].status]?.text || 'CHECKED IN';
  
  const trip_addons = trip.addOns

  ?.map(    (groupSubscribedAddOn) =>
      groupSubscribedAddOn.bookingAddOn.label
  )
  .join(", ");

  const is_group_booking = trip.bookings.length > 1 ? true : false;
  

  const trip_number_of_rooms = trip.numberOfRooms;

//  const trip_number_of_guests = trip.numberOfGuests;

  const trip_rateplan = trip.ratePlan?.ratePlan?.title;

    //const trip_number_of_nights = trip.bookings[0]?.roomBookings[0]?.numberOfNights;

    const trip_total_number_of_nights = trip.bookings.reduce((total, booking) => {
      const number_of_nights_in_booking = booking.roomBookings.reduce((sum, room_booking) => {
        return sum + (room_booking.numberOfNights ?? 0); // undefined check
      }, 0);
    
      return total + number_of_nights_in_booking;
    }, 0);

    const trip_bills_total = trip.bills?.reduce((sum, bill) => {
      return sum + (bill.total ?? 0); // undefined check
    }, 0);

    const trip_hst = trip.bills?.reduce((sum, bill) => {
      return sum + (bill.HST ?? 0); // undefined check
    }, 0);

    const trip_mat = trip.bills?.reduce((sum, bill) => {
      return sum + (bill.MAT ?? 0); // undefined check
    }, 0);

    const bookings = trip.bookings;

    const guestsArray = Object.keys(bookings).map((index) => {
      
      const checkInDate = moment (bookings[index].checkInDate).format("ddd DD MMM h:mm A");
      const checkOutDate = moment (bookings[index].checkOutDate).format("ddd DD MMM h:mm A");

      return (
        <div className="guestsTableContent" key={index}>
          <div className="col name">{bookings[index].fullName}</div>
          <div className="col">{bookings[index].phoneNumber}</div>
          <div className="col">{checkInDate}</div>
          <div className="col">{checkOutDate}</div>
          <div className="col">
            <span className="edit">Edit</span>
          </div>
        </div>
      );
    })

    const roomsArray = Object.keys(bookings).map((index) => {
      
      const checkInDate = moment (bookings[index].checkInDate).format("ddd DD MMM h:mm A");
      const checkOutDate = moment (bookings[index].checkOutDate).format("ddd DD MMM h:mm A");

      let rooomNumber = +index + 1;

      const trip_checkin = moment(
        trips[groupId].bookings?.reduce((min, current) => {
          return current.checkInDate < min ? current.checkInDate : min;
        }, trips[groupId].bookings[index]?.checkInDate)
      ).format("ddd, DD MMM"); // old format was format("ddd DD MMM, h:mm A")
    
      const trip_checkout = moment(
          trips[groupId].bookings?.reduce((max, current) => {
            return current.checkOutDate > max
              ? current.checkOutDate
              : max;
          }, trips[groupId].bookings[index]?.checkOutDate)
        ).format("ddd, DD MMM"); // old format was format("ddd DD MMM, h:mm A")
    

      const trip_confirmation_code = trip.bookings[index]?.reservationCode;
      
      const trip_number_of_guests = trip.bookings[index].tenantGroup.tenant.user;

      //console.log(trip_number_of_guests);

      return (
        <div className="room" key={rooomNumber}>
          <div className="room">Room {rooomNumber}</div>
        <div className="detailsHeader">
            STATUS:{" "}
            <span className="checkedIn"> {trip_status} (??? - hardcoded)</span>
          </div>
          <div className="detailsSmall">
            CHECK-IN/CHECK-OUT{" "}
            <span>
              {trip_checkin} - {trip_checkout}
            </span>
            <div className="accountLink">
              <Icon width={14} height={14}>
                <EditIcon />
              </Icon>
              <div className="link">Modify Dates</div>
            </div>
          </div>
          <div className="detailsLarge">
            CONFIRMATION <span>{trip_confirmation_code}</span>
          </div>
          <div className="detailsSmall">
            GUESTS{" "}
            <span>1
              {/* {trip_number_of_guests} */}
            </span>
          </div>
          <div className="detailsSmall">
            EXTRA <span>{trip_addons} </span>
            {/* <Icon width={14} height={14}>
              <InfoCircle />
            </Icon> */}
            <div className="accountLink">
              <Icon width={14} height={14}>
                <EditIcon />
              </Icon>
              <div className="link">Modify Addons</div>
            </div>

          </div>
          <div className="detailsLarge">
            RATE{" "}
            <span>
              Advanced Purchase (10% off) (??? - hardcoded) {trip_rateplan}
            </span>
          </div>
        </div>
      );
    })


    if (debug) {
      console.log(trip);
      //console.log (trips);

      console.log (is_group_booking);
    }
  

  return (
    <AccountContentContainer>
      <div className="accountBody">
        <div className="cardContent">
          {roomsArray}

          <div className="detailsLarge breakDown">BREAKDOWN</div>
          <div className="breakDownLine">
            <div className="breakDownTitle">
              {trip_number_of_rooms} Room - {trip_total_number_of_nights} Nights
            </div>
            <div className="breakDownDots"></div>
            <div className="breakDownNumber"></div>
          </div>
          <div className="breakDownLine">
            <div className="breakDownTitle breakDownTitleWithPadding">
              Queen bed w/ skylight
            </div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">$???.??</div>
          </div>
          <div className="breakDownLine breakDownLineRed">
            <div className="breakDownTitle">Advanced Purchase (10% off)</div>
            <div className="breakDownDots">
              <div className="dashedLineRed"></div>
            </div>
            <div className="breakDownNumber">-$???.??</div>
          </div>
          <div className="breakDownLine breakDownLineRed">
            <div className="breakDownTitle">Coupon Code existentialdread</div>
            <div className="breakDownDots">
              <div className="dashedLineRed"></div>
            </div>
            <div className="breakDownNumber">-$???.??</div>
          </div>
          <div className="breakDownLine">
            <div className="breakDownTitle">Municipal Accommodation Tax</div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">${trip_mat}</div>
          </div>
          <div className="breakDownLine">
            <div className="breakDownTitle">Parking</div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">$???.??</div>
          </div>
          <div className="breakDownLine">
            <div className="breakDownTitle">Breakfast</div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">$???.??</div>
          </div>
          <div className="breakDownLine">
            <div className="breakDownTitle">Late Checkout</div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">$???.??</div>
          </div>
          <div className="breakDownLine">
            <div className="breakDownTitle">HST</div>
            <div className="breakDownDots">
              <div className="dashedLine"></div>
            </div>
            <div className="breakDownNumber">${trip_hst}</div>
          </div>
          <div className="breakDownTotal">
            <div className="breakDownTitle">TOTAL</div>
            <div className="breakDownDots"></div>
            <div className="breakDownNumber">${trip_bills_total}</div>
          </div>
          <div className="downloadReceipt">
            <div className="accountLink">
              <Icon width={17} height={18}>
                <DownloadRecept />
              </Icon>
              <div className="link">Download Receipt - ???</div>
            </div>
          </div>
        </div>
        {is_group_booking ? (
        <div className="cardContent">
          <h2 className="guestsHeader">Guest Information</h2>
          <div className="guestsTableHeader">
            <div className="col">GUEST NAME</div>
            <div className="col">PHONE NUMBER</div>
            <div className="col">CHECK-IN</div>
            <div className="col">CHECK-OUT</div>
            <div className="col">ACTION</div>
          </div>
            {guestsArray}
        </div>
        ) : null}
      </div>
      {/* <Card padding="32px">
        <div className="cardTitle">
          <span>Status: </span>
          <span className="highLight">{trip?.status}</span>
        </div>
        <div className="cardContent">
          <div className="breakDownRow">
            <div className="breakDownLabel">CHECK-IN/CHECK-OUT</div>
            <div className="breakDownValue">
              {moment(
                trips[groupId].bookings?.reduce((min, current) => {
                  return current.checkInDate < min ? current.checkInDate : min;
                }, trips[groupId].bookings[0]?.checkInDate)
              ).format("ddd DD MMM, h:mm A") +
                "-" +
                moment(
                  trips[groupId].bookings?.reduce((max, current) => {
                    return current.checkOutDate > max
                      ? current.checkOutDate
                      : max;
                  }, trips[groupId].bookings[0]?.checkOutDate)
                ).format("ddd DD MMM, h:mm A")}
            </div>
          </div>
          <div className="breakDownRow">
            <div className="breakDownLabel">CONFIRMATION</div>
            <div className="breakDownValue">
              ${trip.bookings[0]?.reservationCode}
            </div>
          </div>
          <div className="breakDownRow">
            <div className="breakDownLabel">ROOMS / GUESTS</div>
            <div className="breakDownValue">
              {trip.numberOfRooms + "/" + trip.numberOfGuests}
            </div>
          </div>
          <div className="breakDownRow">
            <div className="breakDownLabel">EXTRA</div>
            <div className="breakDownValue">
              {trip.addOns
                ?.map(
                  (groupSubscribedAddOn) =>
                    groupSubscribedAddOn.bookingAddOn.label
                )
                .join(", ")}
            </div>
          </div>
          <div className="breakDownRow">
            <div className="breakDownLabel">RATE</div>
            <div className="breakDownValue">
              ${trip.ratePlan?.ratePlan?.title}
            </div>
          </div>
          <div className="breakDown">
            <div className="breakDownTitle">BREAKDOWN</div> */}

      {/* <div className="breakDownRow">
              <div className="breakDownLabel">
                {((trip.numberOfRooms ?? 0) > 1 ? (trip.numberOfRooms ?? 0) + ' Rooms' : (trip.numberOfRooms ?? 0) + ' Room')
                  + ' - '  + (nights>1? nights + ' Nights ': nights + ' Night ')
                }
                <br />
                {'\u00a0\u00a0\u00a0\u00a0' + (.bed || 'Queen bed') + ' w/'
                  + (persistSelectedRooms.hasWindows? ' Windows': ' skylight')
                }
              </div>
              <div className="breakDownValue">
                ${roomFee.toFixed(2)}
              </div>
            </div> */}
      {/* {couponSuccess && (
              <div className="breakDownRow">
                <div className="breakDownLabel">
                  <span className="warning">{`Coupon (${trip.coupon.rateType==='FIXED_RATE'? '$'+ trip.coupon.discount + ' off': trip.coupon.discount + '% off'})`}</span>
                </div>
                <div className="breakDownValue">
                  <span className="warning">-${couponDiscount.toFixed(2)}</span>
                </div>
              </div>
            )}
            {ratePlanId===ADVANCED && (
              <div className="breakDownRow">
                <div className="breakDownLabel">
                  <span className="warning">Advanced Purchase (10% off)</span>
                </div>
                <div className="breakDownValue">
                  <span className="warning">-${ratePlanDiscount.toFixed(2)}</span>
                </div>
              </div>
            )}
            <div className="breakDownRow">
              <div className="breakDownLabel">
                Municipal Accommodation Tax
              </div>
              <div className="breakDownValue">
                ${MAT.toFixed(2)}
              </div>
            </div> */}
      {/* </div>
          i
        </div>
      </Card> */}
    </AccountContentContainer>
  );
};

export default BookingDetailsContent;
