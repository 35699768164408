import { atom } from 'recoil';

export const leavingOfferShowed = atom({
  key: 'leavingOfferShowed',
  default: false,
});

export const ratingModalOpenState = atom({
  key: 'ratingModalOpenState',
  default: false,
});

export const submitModalOpenState = atom({
  key: 'submitModalOpenState',
  default: false,
});

export const resultModalOpenState = atom({
  key: 'resultModalOpenState',
  default: false,
});

export const ratingValue = atom({
  key: 'ratingValue',
  default: 0,
});

export const ratingPosition = atom({
  key: 'ratingPosition',
  default: '',
});

export const editModalOpenState = atom({
  key: 'editModalOpenState',
  default: false,
});

export const editPosition = atom({
  key: 'editPosition',
  default: '',
});

export const editIndex = atom({
  key: 'editIndex',
  default: 0,
});

export const guestInfoModalOpenState = atom({
  key: 'guestInfoModalOpenState',
  default: false,
});

export const guestInfoPosition = atom({
  key: 'guestInfoPosition',
  default: '',
});

export const guestInfoIndex = atom({
  key: 'guestInfoIndex',
  default: 0,
});

export const changeRoomTypeModalOpenState = atom({
  key: 'changeRoomTypeModalOpenState',
  default: false,
});

export const changeRoomTypePosition = atom({
  key: 'changeRoomTypePosition',
  default: '',
});

export const changeRoomTypeIndex = atom({
  key: 'changeRoomTypeIndex',
  default: 0,
});

export const cancelBookingModalOpenState = atom({
  key: 'cancelBookingModalOpenState',
  default: false,
});

export const cancelReasonModalOpenState = atom({
  key: 'cancelReasonModalOpenState',
  default: false,
});

export const cancelGroupId = atom({
  key: 'cancelGroupId',
  default: '',
});

export const cancelBookingId = atom({
  key: 'cancelBookingId',
  default: '',
});
