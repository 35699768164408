import styled from 'styled-components';
import { BasicSectionContainer } from '../style';
import { styleSizes } from 'constants/styleSizes';

export const AccountContainer = styled(BasicSectionContainer)`
  min-height: 50vh;
  padding: 64px 0;
  display: flex;
  justify-content: center;

  @media (max-width: ${styleSizes.tabletBreakpoin}) {
    padding: 50px 0;
  }

  @media (max-width: ${styleSizes.mobileBreakpoint}) {
    padding: 32px 0;
  }

  .header {
    width: 100%;
    text-align: left;

    h1 {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    h2 {
      font-size: 24px;
      font-weight: 600;
      @media (max-width: ${styleSizes.tabletBreakpoin}) {
          display: none;
      }
    }
  }

  .account {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 32px;
    margin-top: 32px;
    flex-direction: row;
    align-items: stretch;
    @media (max-width: ${styleSizes.miniBreakpoint}) {
        //padding: 10px;
        flex-direction: column-reverse;
        gap: 48px;
      }
          @media (max-width: ${styleSizes.mobileBreakpoint}) {
        padding: 0;

      }
  }

`;
